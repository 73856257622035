import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface PaginationParameters {
    readonly per_page: number;
    readonly query: string;
    readonly page: number;
    readonly sort_by: string;
    readonly order: string;
}
interface GetStreamConfigsRequest {
    readonly stream_ids: string[];
}
type FilterTypeInfoArray = FilterTypeInfo[];
interface SizeEstimatesOverview {
    readonly size_estimates: {
        readonly [_key: string]: SizeEstimate;
    };
    readonly total_size_bytes: number;
    readonly total_files_count: number;
    readonly from: string;
    readonly to: string;
    readonly row_count: number;
}
interface DataWarehouseConfigEnableRequest {
    readonly stream_ids: string[];
    readonly enabled: boolean;
}
interface DataWarehouseArchivingConfiguration {
    readonly archive_name: string;
    readonly stream_id: string;
    readonly retention_time: string;
    readonly id: string;
    readonly enabled: boolean;
}
interface RestoreIndex {
    readonly index_set_id: string;
    readonly index_name: string;
}
interface SizeEstimate {
    readonly size_bytes: number;
    readonly files_count: number;
    readonly archive_name: string;
    readonly table_identifier: string;
    readonly row_count: number;
}
interface RetrievalFilter {
    readonly value: Object;
    readonly field_name: string;
}
type stringArray = string[];
interface DataWarehouseDeletionBulkRequest {
    readonly stream_ids: string[];
}
interface anyMap {
    readonly [_key: string]: unknown;
}
interface RetentionTimeChangeRequest {
    readonly retention_time: string;
}
interface DataWarehouseArchive {
    readonly timestamp_to: string;
    readonly last_known_stream_title: string;
    readonly archive_config_id: string;
    readonly stream_id: string;
    readonly id: string;
    readonly message_count: number;
    readonly timestamp_from: string;
    readonly restore_history: RestoreOperation[];
}
interface EntityAttribute {
    readonly filterable: boolean;
    readonly related_property: string;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'DOUBLE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
type DataWarehouseArchivingConfigurationArray = DataWarehouseArchivingConfiguration[];
interface FilterTypeInfo {
    readonly class: string;
    readonly in_memory_filter: boolean;
    readonly normalize_value: boolean;
    readonly field_name: string;
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
type RetrievalFilterArray = RetrievalFilter[];
interface EntityDefaults {
    readonly sort: Sorting;
}
interface RestoreOperation {
    readonly timestamp_to: string;
    readonly partially_restored: boolean;
    readonly restored_documents: number;
    readonly restore_indices: RestoreIndex[];
    readonly index_failures: number;
    readonly id: string;
    readonly restored_at: string;
    readonly timestamp_from: string;
}
interface PageListResponse_DataWarehouseArchive {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: DataWarehouseArchive[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface RetrievalBulkRequest {
    readonly field_filters: RetrievalFilter[];
    readonly stream_ids: string[];
    readonly inclusion_type: 'DATA_WAREHOUSE_ONLY' | 'INDEXER_ONLY' | 'BOTH';
}
/**
 * Returns all archives
 */
export function getArchives(paginationparameters?: PaginationParameters): Promise<PageListResponse_DataWarehouseArchive> {
    return __request__('GET', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse', paginationparameters, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete retrieved Archive
 */
export function deleteRestoredArchive(streamId: string, restoredOperationId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/archive/${streamId}/retrieved/${restoredOperationId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns all filterable fields in an archive
 */
export function filterableFields(): Promise<FilterTypeInfoArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/filterable_fields', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete data from the requested Archives
 */
export function remove(stream_ids: DataWarehouseDeletionBulkRequest = { "stream_ids": [] }, from?: string, to?: string, full_delete?: boolean, wipe_restores?: boolean): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/delete', stream_ids, { 'from': from, 'to': to, 'full_delete': full_delete, 'wipe_restores': wipe_restores }, {
        'Accept': ['application/json']
    });
}
/**
 * Get archive progress
 */
export function progress(): Promise<anyMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/progress', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get job progresses for a specific stream id
 */
export function progressBystreamId(streamId: string): Promise<anyMap> {
    return __request__('GET', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/progress/${streamId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Restores the requested Archives
 */
export function retrieve(retrieval_bulk_request: RetrievalBulkRequest = { "stream_ids": [], "inclusion_type": "BOTH", "field_filters": [] }, from?: string, to?: string): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/retrieve', retrieval_bulk_request, { 'from': from, 'to': to }, {
        'Accept': ['application/json']
    });
}
/**
 * Cancel specified data warehouse job
 */
export function cancel(jobId: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/jobs/${jobId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get an archive estimate overview for a list of streams
 */
export function restoreEstimate(stream_ids: stringArray, from: string, to: string, inclusion_type: 'DATA_WAREHOUSE_ONLY' | 'INDEXER_ONLY' | 'BOTH' = 'BOTH', field_filters?: RetrievalFilterArray): Promise<SizeEstimatesOverview> {
    return __request__('POST', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/size_estimate', field_filters, { 'stream_ids': stream_ids, 'from': from, 'to': to, 'inclusion_type': inclusion_type }, {
        'Accept': ['application/json']
    });
}
/**
 * Get data warehouse configurations for given stream ids
 */
export function getStreamConfigs(streamId: GetStreamConfigsRequest): Promise<DataWarehouseArchivingConfigurationArray> {
    return __request__('POST', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/config', streamId, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Set enabled status for a list of configuration ids
 */
export function toggleConfigEnableStatus(enable: DataWarehouseConfigEnableRequest = { "stream_ids": [], "enabled": true }): Promise<void> {
    return __request__('POST', '/plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/config/enable', enable, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get data warehouse archive for a stream id
 */
export function getDataWarehouseArchive(streamId?: string): Promise<DataWarehouseArchive> {
    return __request__('GET', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/archive`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get data warehouse configuration for a stream id
 */
export function getStreamConfig(streamId?: string): Promise<DataWarehouseArchivingConfiguration> {
    return __request__('GET', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/config`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Optimize Data Warehouse files for the given stream.
 */
export function optimize(streamId?: string, action?: string, target_file_size_bytes: number = 0, retain_last_num_snapshots: number = 0, expire_snapshots_older_than?: string): Promise<void> {
    return __request__('POST', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/optimize/${action}`, null, { 'target_file_size_bytes': target_file_size_bytes, 'retain_last_num_snapshots': retain_last_num_snapshots, 'expire_snapshots_older_than': expire_snapshots_older_than }, {
        'Accept': ['application/json']
    });
}
/**
 * Overwrites the global retention configuration for a specific stream
 */
export function setArchiveRetentionTime(streamId?: string, retention_time_change_request?: RetentionTimeChangeRequest): Promise<void> {
    return __request__('POST', `/plugins/org.graylog.plugins.datawarehouse/data_warehouse/stream/${streamId}/retention_time`, retention_time_change_request, {}, {
        'Accept': ['application/json']
    });
}
